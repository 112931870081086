import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import BackgroundImage from "gatsby-background-image";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import { css } from "@emotion/react";
import Slider from "react-slick";
import Navbar from "../components/navbar";
import { Helmet } from "react-helmet";
import {
	Trans,
	Link,
	I18nextContext,
	useTranslation,
} from "gatsby-plugin-react-i18next";
import ReactPlayer from "react-player/lazy";

const settingsReferenties = {
	arrows: false,
	dots: false,
	/*centerMode: true,
    centerPadding: "5%",*/
	infinite: true,
	speed: 500,
	slidesToShow: 5,
	autoplaySpeed: 4000,
	autoplay: true,
	slidesToScroll: 1,
	className: "referentiesslider",
	responsive: [
		{
			breakpoint: 992,
			settings: {
				slidesToShow: 3,
				slidesToScroll: 1,
				infinite: true,
				dots: false,
			},
		},
	],
};

const settings = {
	arrows: false,
	dots: true,
	/*centerMode: true,
    centerPadding: "5%",*/
	infinite: true,
	speed: 500,
	slidesToShow: 1,
	autoplaySpeed: 8000,
	autoplay: true,
	slidesToScroll: 1,
};

export default function Index({ data }) {
	const context = React.useContext(I18nextContext);
	const taal = context.language;
	const { t } = useTranslation();
	if (taal === "nl") {
		var content = data.wp.homepage.homepage;
	}
	if (taal === "fr") {
		content = data.wp.homepage.homepage.fra;
	}

	return (
		<Layout>
			<Helmet
				htmlAttributes={{
					lang: `${taal}`,
				}}
			>
				<title>
					{t(
						"Schakel onze erkende asbestverwijderaars in"
					)}
				</title>
				<meta
					name="description"
					content="Onze erkende asbestverwijderaars uit Willebroek zoeken altijd naar de meest veilige oplossing. Ontdek de mogelijkheden bij ons gespecialiseerd bedrijf.}"
				/>
			</Helmet>
			<Navbar></Navbar>
			<BackgroundImage
				className="header"
				fluid={
					data.wp.homepage.homepage.headerAchtergrond.localFile.childImageSharp
						.fluid
				}
				fadeIn="false"
			>
				<div className="headergradient">
					<div className="headercontainer">
						<div className="headertext" css={css``}>
							{content.headerTitel}
						</div>
						<br />
						<div>
							<Link to="/over-ons">
								<button>{content.knop}</button>
							</Link>
						</div>
					</div>
				</div>
			</BackgroundImage>
			<section
				css={css`
					max-width: 1300px;
					margin: auto auto;
				`}
			>
				<Slider {...settingsReferenties}>
					{data.wp.homepage.homepage.referenties.map(
						({ index, id, localFile, altText }) => (
							<div
								key={index}
								css={css`
									height: 100px;
									text-align: center;
									margin: auto auto;
								`}
							>
								<GatsbyImage
									image={localFile.childImageSharp.gatsbyImageData}
									alt={altText}
									layout="fixed"
								/>
							</div>
						)
					)}
				</Slider>
			</section>

			<section
				css={css`
					max-width: 1300px;
					margin: auto auto;
					margin-top: 3rem;
					padding-bottom: 3rem;
				`}
			>
				<h1
					className="headerhome"
					css={css`
						padding-bottom: 2rem;
						font-size: 2rem;
					`}
				>
					Nieuws
				</h1>
				<div className="widgetcontainer" css={css``}>
					{data.allWpPost.edges.map(({ index, node }) => {
						if (node.nieuws.categorie === "nieuws") {
							var widgetstyle = "nieuwswidget";
							var categoriestyle = "nieuws";
							var backgroundimage = false;
						} else {
							widgetstyle = "projectwidget";
							categoriestyle = "project";
							backgroundimage = true;
						}
						if (node.nieuws.categorie === "nieuws" && taal === "nl") {
							var categorie = "nieuws";
						}
						if (node.nieuws.categorie === "nieuws" && taal === "fr") {
							var categorie = "actualités";
						}
						if (node.nieuws.categorie === "project" && taal === "nl") {
							var categorie = "project";
						}
						if (node.nieuws.categorie === "project" && taal === "fr") {
							var categorie = "projet";
						}

						return (
							<div className="widgetmobile">
								{backgroundimage === false && (
									<div key={index} className={widgetstyle}>
										<div>
											<div
												css={css`
													margin-bottom: 1rem;
												`}
											>
												<span className={categoriestyle}>{categorie}</span>
											</div>
											<h3
												css={css`
													margin-bottom: 1rem;
												`}
											>
												<Link to={`/nieuws/${node.slug}`}>{node.title}</Link>
											</h3>

											<div
												css={css`
													width: 100%;
												`}
											>
												{node.nieuws.korteInleiding}
											</div>
										</div>
										<Link
											className="tekstbutton-yellow"
											to={`/nieuws/${node.slug}`}
										>
											<Trans>Lees Meer</Trans>
										</Link>
									</div>
								)}

								{backgroundimage === true && (
									<div
										style={{
											backgroundImage: `url(${node.nieuws.afbeeldingsgallerij[0].localFile.publicURL})`,
											backgroundPosition: "center center",
											backgroundSize: "cover",
										}}
										key={index}
										className={widgetstyle}
									>
										<div className="projectcontainer">
											<div
												css={css`
													width: 100%;
												`}
											>
												<div
													css={css`
														margin-bottom: 1rem;
													`}
												>
													<span className={categoriestyle}>{categorie}</span>
												</div>
												<h3
													css={css`
														margin-bottom: 1rem;
													`}
												>
													<Link to={`/nieuws/${node.slug}`}>{node.title}</Link>
												</h3>

												<div>{node.nieuws.korteInleiding}</div>
											</div>
											<Link
												className="tekstbutton-orange"
												to={`/nieuws/${node.slug}`}
											>
												<Trans>Lees Meer</Trans>
											</Link>
										</div>
									</div>
								)}
							</div>
						);
					})}
				</div>
				<div
					className="alleartikels"
					css={css`
						margin-bottom: 6rem;
					`}
				>
					<Link className="tekstbutton" to={`/nieuws`}>
						<Trans>ALLE ARTIKELS</Trans>
					</Link>
				</div>
			</section>

			<section
				css={css`
					max-width: 1300px;
					margin: auto auto;
					margin-top: 0rem;
					padding-bottom: 8rem;
				`}
			>
				<h1
					className="headerhome"
					css={css`
						padding-bottom: 2rem;
						font-size: 2rem;
					`}
				>
					<Trans>Over Ons</Trans>
				</h1>
				<div className="video">
					<div
						css={css`
							flex-basis: 45%;
						`}
					>
						<h2
							css={css`
								padding-bottom: 0.8rem;
							`}
						>
							<Trans>Erkende asbestverwijderaars</Trans>
						</h2>
						<p
							css={css`
								padding-bottom: 1rem;
							`}
						>
							<Trans>
							Wanneer u professionele en erkende asbestverwijderaars wilt inschakelen voor een asbestsanering, doet u een beroep op de expertise van ons bedrijf. Asbitech uit Willebroek is in 2013 opgericht en werkt als asbestverwijderaar over heel Vlaanderen, zowel voor kleine als grote projecten. Als erkende asbestverwijderaars met tientallen jaren aan ervaring in zowel de civiele bouw als petrochemie, beschikken wij over de nodige expertise om elke asbestsanering op een veilige en professionele manier uit te voeren. 
							</Trans>
						</p>
						<h2
							css={css`
								padding-bottom: 0.8rem;
							`}
						>
							<Trans>
								Ontdek hoe wij als erkende asbestverwijderaars te werk gaan
							</Trans>
						</h2>
						<p css={css`
								padding-bottom: 1rem;
							`}>
							<Trans>
							Bent u op zoek naar een asbestverwijderaar die altijd voor een veilige oplossing zorgt en actief is in heel Vlaanderen? Wij staan voor u klaar. Als professionele asbestverwijderaars voeren we een erkende asbestsanering telkens op maat uit en nemen we veiligheid altijd in acht. In het filmpje hiernaast verwijderen onze specialisten bijvoorbeeld losgebonden picalbeplating die gebruikt werd als binnenafwerking voor een garagedak.</Trans>  
							<Trans>Om</Trans>{" "}
								<Link to="/asbestsanering"><Trans>asbest te saneren</Trans></Link>,
								<Trans>dient het dak hermetisch afgesloten te worden. Dat is noodzakelijk, omdat een dak langs buiten niet luchtdicht is. Door het hermetisch afsluiten zorgt onze erkende asbestverwijderaar ervoor dat er geen asbestdeeltjes ontsnappen tijdens de asbestsanering. Deze techniek is de enige veilige manier om losgebonden asbesthoudende toepassingen te saneren. Bovendien is het onze specialiteit. Voor een andere situatie zullen onze professionele en erkende asbestverwijderaars telkens analyseren op welke manier we het best</Trans> 

								{" "}
								<Link to="/asbestsanering/asbest-laten-verwijderen">
								<Trans>asbest kunnen verwijderen</Trans>
								</Link>
								
								<Trans>. Dat gebeurt in overleg met deskundigen op het gebied van gezondheid en veiligheid met aandacht voor mens en omgeving. Een erkende asbestsanering bij ons staat dus gelijk aan veiligheid en professionaliteit.</Trans>
							
						</p>

						<h2
							css={css`
								padding-bottom: 0.8rem;
							`}
						>
							<Trans>
								Blijf op de hoogte van het laatste nieuws in verband met asbest
							</Trans>
						</h2>
						<p>
							<Trans>
							<Trans>Het</Trans> {" "}
								<Link to="/asbestsanering/asbest-afvoeren"><Trans>afvoeren van asbest</Trans></Link>
								<Trans>heeft voor de overheid een hoge prioriteit, daarom doen zich steeds nieuwe ontwikkelingen voor. Als professionele en erkende asbestverwijderaars houden we u graag op de hoogte van nieuwe ontwikkelingen op het gebied van asbestsanering. U volgt ons nieuws onderaan deze pagina of door</Trans> 
								{" "}
								<Link to="/nieuws">
									<Trans>onze nieuwspagina</Trans>
								</Link>
								<Trans>te volgen. Zo blijft u op de hoogte van nieuwe ontwikkelingen en komt u niet voor verrassingen te staan. Lees ook zeker hoe u</Trans> 
								{" "}
								<Link to="/asbestsanering/asbest-herkennen-brussel">
									<Trans>asbest kunt herkennen</Trans>
								</Link>
								<Trans>. Heeft u nog vragen voor onze asbestverwijderaars? Aarzel dan niet om ons te contacteren!</Trans>

							</Trans>
						</p>
						<Link className="tekstbutton-yellow" to={`/over-ons`}>
							<Trans>Lees Meer</Trans>
						</Link>
					</div>
					<div
						css={css`
							flex-basis: 45%;
							margin-bottom: 2rem;
						`}
					>
						<div
							css={css`
								max-height: 700px;
								width: 100%;
								position: relative;
								padding-top: 56.25%;
							`}
						>
							<ReactPlayer
								url="https://www.youtube.com/watch?v=5B-Yfe2Xooo"
								className="react-player"
								playing
								controls={true}
								width="100%"
								height="100%"
								light="https://img.youtube.com/vi/5B-Yfe2Xooo/maxresdefault.jpg"
							/>
						</div>
					</div>
				</div>
			</section>

			<section
				css={css`
					margin: auto auto;
				`}
			>
				<Slider {...settings}>
					{content.slider.map(
						({
							index,
							achtergrondkleur,
							afbeeldingspositieVerticaal,
							doelgroep,
							afbeelding,
							tekst,
							tekstkleur,
							titel,
						}) => {
							if (tekstkleur === "#ffffff") {
								var leesMeer = "tekstbutton-wit";
							} else {
								leesMeer = "tekstbutton";
							}
							return (
								<BackgroundImage
									key={index}
									fluid={afbeelding.localFile.childImageSharp.fluid}
									alt={afbeelding.altText}
									css={css`
										width: 100%;
										height: 500px;
										background-size: cover;
										background-position: 50% ${afbeeldingspositieVerticaal}%;
									`}
								>
									<div
										css={css`
											display: flex;
											max-width: 1300px;
											margin: auto auto;
											height: 100%;
											align-items: flex-end;
										`}
									>
										<div
											className="slidercontainer"
											css={css`
												color: ${tekstkleur};
												background-color: ${achtergrondkleur};
												padding: 1rem;
												margin-bottom: 4rem;
											`}
										>
											<h1>{titel}</h1>
											{tekst}
											<br />
											<br />
											<Link
												className={leesMeer}
												css={css`
													color: ${tekstkleur};
												`}
												to={`/diensten#${doelgroep}`}
											>
												<Trans>Lees Meer</Trans>
											</Link>
										</div>
									</div>
								</BackgroundImage>
							);
						}
					)}
				</Slider>
			</section>
		</Layout>
	);
}

export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
		wp {
			homepage {
				homepage {
					fra {
						headerAchtergrond {
							localFile {
								childImageSharp {
									fluid(quality: 100) {
										...GatsbyImageSharpFluid_withWebp
									}
								}
							}
						}
						headerTitel
						knop
						slider {
							doelgroep
							afbeeldingspositieVerticaal
							achtergrondkleur
							afbeelding {
								altText
								localFile {
									childImageSharp {
										fluid(quality: 100) {
											...GatsbyImageSharpFluid_withWebp
										}
									}
								}
								altText
							}
							tekst
							tekstkleur
							titel
						}
					}
					headerAchtergrond {
						localFile {
							childImageSharp {
								fluid(quality: 100) {
									...GatsbyImageSharpFluid_withWebp
								}
							}
						}
					}
					headerTitel
					knop
					slider {
						doelgroep
						afbeeldingspositieVerticaal
						achtergrondkleur
						afbeelding {
							altText
							localFile {
								childImageSharp {
									fluid(quality: 100) {
										...GatsbyImageSharpFluid_withWebp
									}
								}
							}
							altText
						}
						tekst
						tekstkleur
						titel
					}
					referenties {
						localFile {
							childImageSharp {
								gatsbyImageData(
									width: 100
									placeholder: TRACED_SVG
									quality: 50
									formats: [WEBP]
								)
							}
						}
						altText
					}
				}
			}
		}
		allWpPost(
			limit: 6
			sort: { fields: date, order: DESC }
			filter: { language: { locale: { eq: $language } } }
		) {
			edges {
				node {
					id
					nieuws {
						korteInleiding
						categorie
						afbeeldingsgallerij {
							altText
							localFile {
								publicURL
								childImageSharp {
									fluid(quality: 50) {
										...GatsbyImageSharpFluid_withWebp
									}
									gatsbyImageData(
										width: 100
										placeholder: TRACED_SVG
										quality: 50
										formats: [WEBP]
									)
								}
							}
						}
					}
					slug
					title
					uri
				}
			}
		}
	}
`;
